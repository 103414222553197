import { Component, inject } from '@angular/core';
import { RouterModule } from '@angular/router';
import { DropdownUserAccountComponent } from '@components/dropdowns/user-account/user-account.component';
import { SessionService } from '@utils/session.service';

@Component({
  selector: 'sidebar-main-menu',
  templateUrl: './main-menu.component.html',
  styleUrl: './main-menu.component.scss',
  standalone: true,
  imports: [
    DropdownUserAccountComponent,
    RouterModule
  ]
})
export class SidebarMainMenuComponent {
  public session = inject(SessionService);
}
