import { Injectable } from '@angular/core';
import { HttpService } from '@utils/http.service';
import { UtilsService } from '@utils/utils.service';

@Injectable({
  providedIn: 'root'
})
export class UserService {
  constructor(
    private http: HttpService,
    private utils: UtilsService
  ) { }

  public getList = async () => await this.http.get(`/api/v1/users`)
    .then((response: any) => this.utils.orderArrayBy(response.data.content, 'firstName'))

  public getByUsername = async (username: string) => await this.http.get(`/api/v1/users/${username}?by=username`)
    .then((response: any) => response.data)

  public create = async (body: any) => await this.http.post(`/api/v1/users`, body)
    .then((response: any) => response.data)

  public save = async (username: string, body: any) => await this.http.put(`/api/v1/users/${username}?by=username`, body)
    .then((response: any) => response.data)

  public delete = async (username: string) => await this.http.delete(`/api/v1/users/${username}?by=username`)
    .then((response: any) => response.data)
}
