import { CommonModule } from '@angular/common';
import { Component, Input } from '@angular/core';
import { RouterModule } from '@angular/router';
import { NotificationsService } from '@services/notifications.service';

@Component({
  selector: 'notifications-box-bell',
  templateUrl: './box-bell.component.html',
  styleUrl: './box-bell.component.scss',
  standalone: true,
  imports: [
    CommonModule,
    RouterModule
  ],
})
export class NotificationsBoxBellComponent {
  @Input() notifications: any[] = []

  constructor(
    private notificationsService: NotificationsService
  ) { }

  onClickNotification = (id: number) => {
    this.notificationsService.read(Number(id))
      .then((response: any) => { })
  }
}
