import { Component } from '@angular/core';
import { RouterModule } from '@angular/router';
import { NgbTooltipModule } from '@ng-bootstrap/ng-bootstrap';
import { MainShortcutsService } from './main-shortcuts.service';
import { SessionService } from '@utils/session.service';

@Component({
  selector: 'sidebar-main-shortcuts',
  templateUrl: './main-shortcuts.component.html',
  styleUrl: './main-shortcuts.component.scss',
  standalone: true,
  imports: [
    RouterModule,
    NgbTooltipModule
  ]
})
export class SidebarMainShortcutsComponent {
  constructor(
    private mainShortcutsService: MainShortcutsService,
    public session: SessionService
  ) { }

  public lockscreen = () => {
    this.mainShortcutsService.lockscreen()
  }
}
