<section class="page w-100 min-vh-100 d-flex flex-column justify-content-between align-items-center">
  <figure id="background-image">
    <img src="./assets/images/{{this.pictureOfTheDay}}" alt="">
  </figure>
  <div class="locksession">
    <h1 class="display-1 fw-light text-light mb-0 pt-5 fs-4">{{date}}</h1>
  </div>
  <div class="user-session d-flex flex-column justify-content-between align-items-center text-light pb-5">
    <div class="user-session__avatar mb-3">
      <svg xmlns="http://www.w3.org/2000/svg" fill="currentColor" class="bi bi-person-circle" viewBox="0 0 16 16">
        <path d="M11 6a3 3 0 1 1-6 0 3 3 0 0 1 6 0" />
        <path fill-rule="evenodd"
          d="M0 8a8 8 0 1 1 16 0A8 8 0 0 1 0 8m8-7a7 7 0 0 0-5.468 11.37C3.242 11.226 4.805 10 8 10s4.757 1.225 5.468 2.37A7 7 0 0 0 8 1" />
      </svg>
    </div>
    <div class="user-session__info">
      <ng-content></ng-content>
    </div>
  </div>
</section>