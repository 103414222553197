import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { SettingsPageRoutingModule } from './settings-routing.module';
import { SettingsPage } from './settings.component';
import { BackboneComponent } from '@components/backbone/backbone.component';
import { HeaderPageTitleBoxComponent } from '@components/headers/page-title-box/page-title-box.component';
import { FormsModule, ReactiveFormsModule } from '@angular/forms';
import { NgSelectModule } from '@ng-select/ng-select';
import { InputTextModule } from 'primeng/inputtext';
import { ButtonModule } from 'primeng/button';
import { PlaceholderTableComponent } from '@components/placeholders/table/table.component';
import { InputSwitchModule } from 'primeng/inputswitch';
import { PlaceholderCardFormVerticalComponent } from '@components/placeholders/card-form-vertical/card-form-vertical.component';

@NgModule({
  declarations: [
    SettingsPage
  ],
  imports: [
    CommonModule,
    SettingsPageRoutingModule,
    BackboneComponent,
    HeaderPageTitleBoxComponent,
    ReactiveFormsModule,
    NgSelectModule,
    InputTextModule,
    ButtonModule,
    PlaceholderTableComponent,
    InputSwitchModule,
    FormsModule,
    PlaceholderCardFormVerticalComponent
  ]
})
export class SettingsPageModule { }
