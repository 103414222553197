import { Injectable } from '@angular/core';
import Swal from 'sweetalert2';

@Injectable({
  providedIn: 'root'
})
export class ConfirmDialogService {
  constructor(
  ) { }

  dialog = (title: string = '', confirmButtonText: string = 'Si', cancelButtonText: string = 'No', icon: any = 'warning'): Promise<any> => {
    return new Promise((resolve, reject) => {
      Swal.fire({
        text: title,
        icon: icon,
        showCancelButton: true,
        confirmButtonColor: '#0ab39c',
        cancelButtonColor: '#f06548',
        confirmButtonText: confirmButtonText,
        cancelButtonText: cancelButtonText
      }).then((result) => {
        if (result.isConfirmed) {
          resolve(true)
        } else {
          reject(false)
        }
      })
    })
  }
}
