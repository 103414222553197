import { Injectable } from '@angular/core';
import { AuthService } from '@utils/auth.service';
import { ToastService } from '@utils/toast.service';

@Injectable({
  providedIn: 'root'
})
export class MainShortcutsService {
  constructor(
    private auth: AuthService,
    private toast: ToastService,
  ) { }

  public lockscreen = () => {
    this.auth.lockscreen()
    this.toast.success('Tu pantalla ha sido bloqueada.')
  }
}
