import { NgModule } from '@angular/core';
import { RouterModule, Routes } from '@angular/router';
import { LoginPageModule } from './login/login.module';
import { LockScreenPageModule } from './lock-screen/lock-screen.module';
import { HomePageModule } from './home/home.module';
import { UserModule } from './user/user.module';
import { RfcPageModule } from './rfc/rfc.module';
import { ActaPageModule } from './acta/acta.module';
import { SettingsPageModule } from './settings/settings.module';

const routes: Routes = [];

@NgModule({
  imports: [
    RouterModule.forChild(routes),
    LoginPageModule,
    LockScreenPageModule,
    HomePageModule,
    UserModule,
    RfcPageModule,
    ActaPageModule,
    SettingsPageModule
  ],
  exports: [RouterModule]
})
export class PagesRoutingModule { }
