<div ngbDropdown placement="bottom-end">
  <button type="button"
    class="btn btn-light h-100 rounded-circle d-flex flex-column align-items-center justify-content-center p-0"
    style="width: 40px; height: 40px !important;" id="notifications" ngbDropdownToggle>
    <i class="bi bi-bell-fill fs-5"></i>
    @if (this.countNotifications > 0) {
    <span class="position-absolute translate-middle-y badge rounded-pill bg-danger z-1" style="top: 0;right: 0;">
      {{this.countNotifications}} <span class="visually-hidden">unread messages</span>
    </span>
    }
  </button>
  <div ngbDropdownMenu aria-labelledby="notifications" class="p-0">
    <notifications-box-bell [notifications]="this.notifications" />
  </div>
</div>