import { Injectable } from '@angular/core';
import Swal from 'sweetalert2';

/**
 * Service for displaying toast messages.
 */
@Injectable({
  providedIn: 'root'
})
export class ToastService {
  /**
   * Displays a success toast message.
   *
   * @param message - The success message to display.
   */
  success = (message: any) => this.toast(message)

  /**
   * Displays an error toast message.
   *
   * @param message - The error message to display.
   */
  error = (message: any) => this.toast(message, "error")

  /**
   * Displays a toast message.
   *
   * @param message - The message to be displayed in the toast.
   * @param type - The type of the toast. Defaults to "success".
   * @returns A Promise that resolves when the toast is displayed.
   */
  private toast = (message: any, type: any = "success") => {
    return Swal.mixin({
      toast: true,
      position: 'bottom-end',
      showConfirmButton: false,
      timer: 3000,
      customClass: { popup: 'colored-toast' },
      didOpen: (toast: any) => {
        toast.addEventListener('mouseenter', Swal.stopTimer)
        toast.addEventListener('mouseleave', Swal.resumeTimer)
      }
    }).fire({
      icon: type,
      title: message
    })
  }
}
