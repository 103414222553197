import { Component, Input } from '@angular/core';
import { TopbarComponent } from '@components/headers/topbar/topbar.component';
import { NotificationsAlertsComponent } from '@components/notifications/alerts/alerts.component';
import { SidebarAsidebarComponent } from '@components/sidebars/asidebar/asidebar.component';

@Component({
  selector: 'backbone',
  templateUrl: './backbone.component.html',
  styleUrl: './backbone.component.scss',
  standalone: true,
  imports: [
    TopbarComponent,
    SidebarAsidebarComponent,
    NotificationsAlertsComponent
  ]
})
export class BackboneComponent {
  @Input() currentPage: string = '';
}
