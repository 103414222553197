import { NgModule } from '@angular/core';
import { RouterModule, Routes } from '@angular/router';
import { UserViewPage } from './user-view.component';
import { authGuard } from '@guards/auth.guard';

const routes: Routes = [
  {
    path: 'user/:username',
    component: UserViewPage,
    canActivate: [authGuard],
    data: {
      title: ""
    }
  },
];

@NgModule({
  imports: [RouterModule.forChild(routes)],
  exports: [RouterModule]
})
export class UserViewPageRoutingModule { }
