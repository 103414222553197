import { Component } from '@angular/core';
import { FormBuilder, Validators } from '@angular/forms';
import { ActaService } from '@services/acta.service';
import { SessionService } from '@utils/session.service';
import { ToastService } from '@utils/toast.service';

@Component({
  selector: 'page-settings',
  templateUrl: './settings.component.html',
  styleUrl: './settings.component.scss'
})
export class SettingsPage {
  constructor(
    private formBuilder: FormBuilder,
    private toast: ToastService,
    public session: SessionService,
    private actaService: ActaService
  ) { }

  public isLoading = false;

  async ngOnInit() {
    this.isLoading = true;

    await this.initActas()

    this.isLoading = false;
  }

  /** Settings Actas */
  public actas: any = {
    token: '',
    emulate: 'false',
  }

  initActas = async () => {
    await this.actaService.getSettings()
      .then((settings: any) => {
        this.actas.token = settings.content.sirabiAccessToken
        this.actas.emulate = (settings.content.sirabiEmulate === 'true') ? true : false
      })
  }

  public isSavingToken = false;

  saveToken = () => {
    this.isSavingToken = true;

    this.actaService.updateToken({ token: this.actas.token })
      .then(() => {
        this.toast.success('Actualizado');

        this.ngOnInit()
      })
      .catch(() => {
        this.toast.error('Error al guardar la configuración');
      })
      .finally(() => {
        this.isSavingToken = false;
      })
  }

  public isSavingEmulate = false;

  saveEmulate = () => {
    this.isSavingEmulate = true;

    this.actaService.updateEmulate({ emulate: String(this.actas.emulate) })
      .then(() => {
        this.toast.success('Actualizado');
      })
      .catch(() => {
        this.toast.error('Error al guardar la configuración');
      })
      .finally(() => {
        this.isSavingEmulate = false;
      })
  }
  /** END Settings Actas */
}
