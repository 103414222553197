import { Component } from '@angular/core';

@Component({
  selector: 'placeholder-card-form-vertical',
  standalone: true,
  imports: [],
  templateUrl: './card-form-vertical.component.html',
  styleUrl: './card-form-vertical.component.scss'
})
export class PlaceholderCardFormVerticalComponent {
}
