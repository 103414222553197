import { Injectable } from '@angular/core';
import { HttpService } from '@utils/http.service';

@Injectable({
  providedIn: 'root'
})
export class ActaService {
  constructor(
    private http: HttpService
  ) { }

  public create = async (body: any) => await this.http.post(`/api/v1/acta`, body)
    .then((response: any) => response.data)

  public list = async () => await this.http.get(`/api/v1/acta`)
    .then((response: any) => response.data)

  public getSettings = async () => await this.http.get(`/api/v1/settings/acta`)
    .then((response: any) => response.data)

  public updateToken = async (body: any) => await this.http.put(`/api/v1/settings/acta/update-token`, body)
    .then((response: any) => response.data)

  public updateEmulate = async (body: any) => await this.http.put(`/api/v1/settings/acta/update-emulate`, body)
    .then((response: any) => response.data)
}
