import { EventEmitter, Injectable, Output } from '@angular/core';
import { environment } from '@environments/environment';
import { SessionService } from '@utils/session.service';
import { Socket } from 'ngx-socket-io';

@Injectable({
  providedIn: 'root'
})
export class WebsocketService extends Socket {
  @Output() outEven: EventEmitter<any> = new EventEmitter();

  constructor(
    private session: SessionService
  ) {
    super({ url: `${environment.notification.apiUrl}` })
    this.ioSocket.on('emitNotification', (res: any) => this.outEven.emit(res))
  }

  registerOnlineUser = () =>
    this.ioSocket.emit('registerOnlineUser', { username: String(this.session.getUser()?.userId), apiKey: environment.notification.apiKey });
}
