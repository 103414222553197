import { Component, Input } from '@angular/core';
import { BreadcrumbComponent } from '@components/breadcrumb/breadcrumb.component';

@Component({
  selector: 'header-page-title-box',
  standalone: true,
  imports: [
    BreadcrumbComponent
  ],
  templateUrl: './page-title-box.component.html',
  styleUrl: './page-title-box.component.scss'
})
export class HeaderPageTitleBoxComponent {
  @Input() title: string = '';
  @Input() links: Array<any> = [];
}
