<backbone currentPage="Settings">
  <header-page-title-box title="Mis ajustes" [links]="[{title: 'Configuraciones'}]" />
  <div class="p-3">
    @if (this.isLoading === true) {
    <placeholder-card-form-vertical />
    }
    @else {
    @if (['sysadmin','administrator'].includes(this.session.getDataToken()['permissions'][0])) {
    <div class="card page-section rounded-2 shadow-sm">
      <div class="card-header align-items-center d-flex rounded-top-2">
        <h6 class="card-title flex-grow-1 m-0">Configuraciones del módulo de actas.</h6>
      </div>
      <div class="card-body">
        <div class="d-flex flex-column gap-2">
          @if (this.session.existsPermissions(['update_token_actas'])) {
          <div class="mb-3 row">
            <label class="col-sm-2 col-form-label d-flex align-items-center justify-content-end">Token Sirabi</label>
            <div class="col-sm-10">
              <div class="d-flex gap-2">
                <input [(ngModel)]="this.actas.token" type="text" pInputText placeholder="Token" class="w-25 h-50px" />

                <p-button label="Actualizar token" styleClass="h-100" (click)="saveToken()"
                  [loading]="this.isSavingToken"></p-button>
              </div>
            </div>
          </div>
          }
          @if (this.session.existsPermissions(['update_emulate_actas'])) {
          <div class="mb-3 row">
            <label class="col-sm-2 col-form-label d-flex align-items-center justify-content-end">Emular peticiones a
              Sirabi</label>
            <div class="col-sm-10">
              <p-inputSwitch [(ngModel)]="this.actas.emulate" (onChange)="saveEmulate()"
                [disabled]="this.isSavingEmulate === true"></p-inputSwitch>
            </div>
          </div>
          }
        </div>
      </div>
    </div>
    }
    }
  </div>
</backbone>