export const environment = {
  app: {
    title: "Trámites Flash",
    apiUrl: "https://tramitesflash.ngrok.app",
  },
  notification: {
    apiKey: "OwHZ0SQM0fxsH3Tgan7apBM86",
    apiUrl: "https://notifications.tramitesflash.com:3001"
  }
};
