import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { LoginPageRoutingModule } from './login-routing.module';
import { LoginPage } from './login.component';
import { LockScreenComponent } from '@components/lock-screen/lock-screen.component';
import { FormLoginComponent } from '@components/forms/login/login.component';

@NgModule({
  declarations: [
    LoginPage
  ],
  imports: [
    CommonModule,
    LoginPageRoutingModule,
    LockScreenComponent,
    FormLoginComponent
  ]
})
export class LoginPageModule { }
