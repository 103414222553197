import { CommonModule } from '@angular/common';
import { Component, Input } from '@angular/core';
import { NgbDropdownModule } from '@ng-bootstrap/ng-bootstrap';

@Component({
  selector: 'dropdown-language',
  templateUrl: './language.component.html',
  styleUrl: './language.component.scss',
  standalone: true,
  imports: [
    NgbDropdownModule,
    CommonModule
  ],
})
export class DropdownLanguageComponent {
  @Input() theme: string = 'dark';
}
