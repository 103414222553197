<backbone currentPage="Rfc">
  <header-page-title-box title="Constancias de situación fiscal."
    [links]="[{title: 'RFC',  link: '/rfc'}, {title: 'Lista'}]" />
  <div class="p-3">
    <form [formGroup]="form" [ngClass]="{'opacity-50 pointer-events user-select-none': this.isSaving === true}"
      class="mb-3">
      <div class="card page-section rounded-2 shadow-sm">
        <div class="card-header align-items-center d-flex rounded-top-2">
          <h6 class="card-title flex-grow-1 m-0">Solicitar una constacia de situación fiscial.</h6>
        </div>
        <div class="card-body">
          <div class="d-flex gap-2">
            <input formControlName="name" type="text" pInputText placeholder="Nombre completo" class="w-100 h-50px" />

            <input formControlName="data" type="text" pInputText placeholder="CURP / RFC" class="w-100 h-50px" />

            <p-button label="Solicitar" styleClass="h-100" (click)="request()" [loading]="isSaving"
              [disabled]="!form.valid"></p-button>
          </div>
        </div>
      </div>
    </form>

    @if (this.isLoading === true) {
    <section class="page-section bg-white rounded-2 shadow-sm p-3">
      <placeholder-table />
    </section>
    }
    @else {
    <section class="page-section bg-white rounded-2 shadow-sm">
      <table class="table table-responsive-md table-borderless rounded-2 mb-0">
        <thead class="border-bottom">
          <tr>
            <th scope="col">Nombre</th>
            <th scope="col">RFC</th>
            <th scope="col">CURP</th>
            <th scope="col" class="text-center">Estatus</th>
            <th scope="col">Fecha de creación</th>
            <th></th>
          </tr>
        </thead>

        <tbody>
          @for (item of this.list; track $index) {
          <tr>
            <td class="align-middle fw-medium text-muted">
              {{item.name}}
            </td>
            <td class="align-middle fw-medium text-muted">
              {{item.rfc}}
            </td>
            <td class="align-middle fw-medium text-muted">
              {{item.curp}}
            </td>
            <td class="align-middle fw-medium text-muted text-center">
              @if (item.statusId == 1) { En cola }
              @if (item.statusId == 2) { Finalizado }
              @if (item.statusId == 3) { Error en busqueda }
            </td>
            <td class="align-middle fw-medium text-muted">
              {{item.dateCreated.ISO8601 | date: 'longDate' }}
            </td>
            <td class="align-middle fw-medium text-nowrap text-end">
              @if (item.statusId == 1) {
              <div class="d-flex justify-content-end gap-1">
                <p-button icon="pi pi-download" [disabled]="true"></p-button>
              </div>
              }
              @if (item.statusId == 2) {
              <div class="d-flex justify-content-end gap-1">
                <a href="{{apiUrl}}/api/v1/rfc/download/{{item.idRfcRequest}}" download target="_blank"
                  rel="noopener noreferrer" class="p-button text-decoration-none p-button-success">
                  <i class="pi pi-download"></i>
                </a>
              </div>
              }
              @if (item.statusId == 3) {
              <div class="d-flex justify-content-end gap-1">
                <p-button severity="danger" icon="pi pi-download" [disabled]="true"></p-button>
              </div>
              }
            </td>
          </tr>
          }
          @empty {
          <tr>
            <td scope="row" colspan="6" class="align-middle fw-medium text-center">
              <p class="m-0 p-4 opacity-50">Empty</p>
            </td>
          </tr>
          }
        </tbody>
      </table>
    </section>
    }
  </div>
</backbone>