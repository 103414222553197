import { Component } from '@angular/core';
import { NgbDropdownModule } from '@ng-bootstrap/ng-bootstrap';
import { NotificationsBoxBellComponent } from '../box-bell/box-bell.component';
import { CommonModule } from '@angular/common';
import { RouterModule } from '@angular/router';
import { WebsocketService } from '@services/websocket.service';
import { NotificationsService } from '@services/notifications.service';

@Component({
  selector: 'bell-button',
  standalone: true,
  imports: [
    NgbDropdownModule,
    NotificationsBoxBellComponent,
    CommonModule,
    RouterModule
  ],
  templateUrl: './bell-button.component.html',
  styleUrl: './bell-button.component.scss'
})
export class BellButtonComponent {
  public notifications: any[] = []
  public countNotifications = 0

  constructor(
    private websocket: WebsocketService,
    private notificationsService: NotificationsService
  ) {
    this.websocket.outEven.subscribe(response => {
      if (response.type === 'notification') {
        this.notifications.unshift(this.processNotification(response.data))
      }
    })
  }

  ngOnInit() {
    this.getNotifications()
  }

  getNotifications = () => {
    this.notificationsService.getList()
      .then((response: any) => {
        response.map((response: any) => this.notifications.push(this.processNotification(response)))
      })
  }

  processNotification = (notification: any) => {
    return notification
  }
}
