import { Component } from '@angular/core';
import { SidebarMainMenuComponent } from '../main-menu/main-menu.component';
import { SidebarMainShortcutsComponent } from '../main-shortcuts/main-shortcuts.component';

@Component({
  selector: 'sidebar-asidebar',
  template: '<sidebar-main-shortcuts /> <sidebar-main-menu />',
  standalone: true,
  imports: [
    SidebarMainMenuComponent,
    SidebarMainShortcutsComponent
  ]
})
export class SidebarAsidebarComponent {
}
