import { CommonModule } from '@angular/common';
import { Component, Input } from '@angular/core';
import { RouterModule } from '@angular/router';
import { NgbDropdownModule } from '@ng-bootstrap/ng-bootstrap';
import { AuthService } from '@utils/auth.service';
import { SessionService } from '@utils/session.service';
import { ToastService } from '@utils/toast.service';

@Component({
  selector: 'dropdown-user-account',
  templateUrl: './user-account.component.html',
  styleUrl: './user-account.component.scss',
  standalone: true,
  imports: [
    NgbDropdownModule,
    CommonModule,
    RouterModule
  ],
})
export class DropdownUserAccountComponent {
  constructor(
    private auth: AuthService,
    private toast: ToastService,
    private session: SessionService
  ) { }

  @Input() theme: string = 'dark';
  public user = this.session.getUser()?.username

  public logout = () => {
    this.auth.logout()
    this.toast.success('Se ha cerrado la sesión.')
  }
}
