<form [formGroup]="formLogin" class="group d-flex p-1 text-bg-light rounded-pill" autocomplete="off"
  [ngClass]="{ 'bg-danger': password?.invalid && (password?.dirty || password?.touched) && password?.errors }">
  <input formControlName="password" name="password" type="password"
    class="form-control border-0 rounded-pill bg-transparent w-100" placeholder="Password">
  <button type="submit" class="btn btn-primary rounded-circle" style="width: 40px;height: 40px;"
    [disabled]="!formLogin.valid || this.isLoading === true" (click)="login()">
    <i class="bi bi-key"></i>
  </button>
</form>

<p class="m-0 mt-3 text-center">
  <a href="#" class="text-light fw-light text-decoration-none" (click)="clearSession()">Ingresar con otro usuario</a>
</p>