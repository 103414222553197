import { Injectable } from '@angular/core';

@Injectable({
  providedIn: 'root'
})
export class NotificationsService {
  getList = () => {
    return new Promise((resolve, reject) => resolve([]));
  }

  read = (id: number) => {
    return new Promise((resolve, reject) => resolve([]));
  }
}
