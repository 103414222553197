import { Component } from '@angular/core';
import { CatalogsService } from '@services/catalogs.service';
import { UserService } from '@services/user.service';

@Component({
  selector: 'page-user-list',
  templateUrl: './user-list.component.html',
  styleUrl: './user-list.component.scss'
})
export class UserListPage {
  public isLoading = true;
  public roles: any = [];

  constructor(
    private userService: UserService,
    private catalogService: CatalogsService
  ) { }

  async ngOnInit() {
    await this.catalogService.permissions().then((response) => this.roles = response)
    this.refresh();
  }

  refresh = () => {
    this.isLoading = true;
    this.getUsers();
  }

  public users: any = [];
  getUsers = async () => {
    await this.userService.getList().then((response) => this.users = response)

    this.isLoading = false
  }

  findRole = (id: number) => this.roles.find((role: any) => role.permissionId === id)
}
