<form [formGroup]="formLogin" class="" autocomplete="off">
  <div class="group d-flex p-1 text-bg-light rounded-pill mb-2"
    [ngClass]="{ 'bg-danger': email?.invalid && (email?.dirty || email?.touched) && email?.errors }">
    <input formControlName="email" name="email" type="text"
      class="form-control border-0 rounded-pill bg-transparent w-100" placeholder="Email" style="height: 40px;">
  </div>
  <div class="group d-flex p-1 text-bg-light rounded-pill"
    [ngClass]="{ 'bg-danger': password?.invalid && (password?.dirty || password?.touched) && password?.errors }">
    <input formControlName="password" name="password" type="password"
      class="form-control border-0 rounded-pill bg-transparent w-100" placeholder="Password">
    <button type="submit" class="btn btn-primary rounded-circle" style="width: 40px;height: 40px;"
      [disabled]="!formLogin.valid || this.isLoading === true" (click)="login()">
      <i class="bi bi-key"></i>
    </button>
  </div>
</form>