import { CommonModule } from '@angular/common';
import { Component } from '@angular/core';
import { FormBuilder, ReactiveFormsModule, Validators } from '@angular/forms';
import { Router } from '@angular/router';
import { AuthService, IAccessAuth } from '@utils/auth.service';
import { SessionService } from '@utils/session.service';
import { ToastService } from '@utils/toast.service';

@Component({
  selector: 'form-login',
  standalone: true,
  imports: [
    CommonModule,
    ReactiveFormsModule
  ],
  templateUrl: './login.component.html',
  styleUrl: './login.component.scss'
})
export class FormLoginComponent {
  constructor(
    private formBuilder: FormBuilder,
    private auth: AuthService,
    private router: Router,
    private toast: ToastService,
    private session: SessionService
  ) { }

  public formLogin = this.formBuilder.group({
    email: ['', Validators.required],
    password: ['', Validators.required],
  });
  public isLoading: boolean = false

  get email() { return this.formLogin.get('email') }
  get password() { return this.formLogin.get('password') }

  ngOnInit() {
    if (this.session.isLoggedIn()) {
      this.router.navigate(['lockscreen'])
    }
  }

  login = (): void => {
    this.isLoading = true
    let body: IAccessAuth = {
      email: `${this.email?.value}`,
      password: `${this.password?.value}`
    }

    this.auth.login(body)
      .then((response) => {
        this.auth.setAuthSession(response.data)
        this.toast.success('Inicio de sesión correcto.')
        this.router.navigate(['/'])
      })
      .catch((error) => {
        this.password?.setValue('')

        error.error.data.labels_errors.forEach((element: any) => this.toast.error(element.message));

        this.isLoading = false
      })
  }
}
