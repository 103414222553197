import { Injectable } from '@angular/core';
import { HttpService } from '@utils/http.service';
import { UtilsService } from '@utils/utils.service';

@Injectable({
  providedIn: 'root'
})
export class CatalogsService {
  constructor(
    private http: HttpService,
    private utils: UtilsService
  ) { }

  public permissions = async () => await this.http.get(`/api/v1/catalog/permission`)
    .then((response: any) => this.utils.orderArrayBy(response.data.content, 'description'))

  public states = async () => await this.http.get(`/api/v1/catalog/states`)
    .then((response: any) => this.utils.orderArrayBy(response.data.content, 'label'))

  public municipalities = async () => await this.http.get(`/api/v1/catalog/states/municipalities`)
    .then((response: any) => this.utils.orderArrayBy(response.data.content, 'label'))

  public municipality = async (stateCode: string) => await this.http.get(`/api/v1/catalog/states/municipality/${stateCode}`)
    .then((response: any) => this.utils.orderArrayBy(response.data.content, 'label'))
}
