import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { HomePageRoutingModule } from './home-routing.module';
import { HomePage } from './home.component';
import { BackboneComponent } from '@components/backbone/backbone.component';
import { HeaderPageTitleBoxComponent } from '@components/headers/page-title-box/page-title-box.component';

@NgModule({
  declarations: [
    HomePage
  ],
  imports: [
    CommonModule,
    HomePageRoutingModule,
    BackboneComponent,
    HeaderPageTitleBoxComponent
  ]
})
export class HomePageModule { }
