import { Component } from '@angular/core';

@Component({
  selector: 'placeholder-table',
  standalone: true,
  imports: [],
  templateUrl: './table.component.html',
  styleUrl: './table.component.scss'
})
export class PlaceholderTableComponent {
}
