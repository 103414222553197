import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { UserViewPageRoutingModule } from './user-view-routing.module';
import { UserViewPage } from './user-view.component';
import { InputPasswordComponent } from '@components/input-password/input-password.component';
import { NgSelectModule } from '@ng-select/ng-select';
import { HeaderPageTitleBoxComponent } from '@components/headers/page-title-box/page-title-box.component';
import { BackboneComponent } from '@components/backbone/backbone.component';
import { RouterModule } from '@angular/router';
import { InputTextModule } from 'primeng/inputtext';
import { InputMaskModule } from 'primeng/inputmask';
import { PasswordModule } from 'primeng/password';
import { ButtonModule } from 'primeng/button';
import { PlaceholderCardFormVerticalComponent } from '@components/placeholders/card-form-vertical/card-form-vertical.component';
import { ReactiveFormsModule } from '@angular/forms';

@NgModule({
  declarations: [
    UserViewPage
  ],
  imports: [
    CommonModule,
    ReactiveFormsModule,
    UserViewPageRoutingModule,
    RouterModule,
    BackboneComponent,
    HeaderPageTitleBoxComponent,
    PlaceholderCardFormVerticalComponent,
    NgSelectModule,
    InputPasswordComponent,
    InputTextModule,
    InputMaskModule,
    PasswordModule,
    ButtonModule
  ]
})
export class UserViewPageModule { }
