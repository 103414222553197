import { CommonModule } from '@angular/common';
import { Component } from '@angular/core';
import { AlertComponent } from '@components/alert/alert.component';
import { WebsocketService } from '@services/websocket.service';

@Component({
  selector: 'notifications-alerts',
  standalone: true,
  imports: [
    CommonModule,
    AlertComponent
  ],
  templateUrl: './alerts.component.html',
  styleUrl: './alerts.component.scss'
})
export class NotificationsAlertsComponent {
  public notifications: any[] = []

  constructor(
    private websocket: WebsocketService
  ) {
    this.websocket.outEven.subscribe(response => {
      if (response.type === 'notification') {
        this.notifications.push({
          type: 'primary',
          message: `${response.data.title}: ${response.data.message}`,
          id: response.data.id,
          url: response.data.url,
        })
      }
    })
  }
}
